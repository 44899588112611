import Entitled from "../assets/ent-logo-min.png";
import DocViewer from "react-doc-viewer";
import {
  Layout,
  Menu,
  Breadcrumb,
  Tabs,
  Card,
  Spin,
  Divider,
  Row,
  Col,
  Input,
  Typography,
  DatePicker,
  Button,
  Table,
  Anchor,
  Statistic,
  Switch,
  Tooltip,
  Upload,
  message,
  Select,
  Progress,
  Modal,
  Form,
  Checkbox,
  Drawer,
} from "antd";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import {
  HighlightOutlined,
  FileOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  UploadOutlined,
  PlusCircleTwoTone,
} from "@ant-design/icons";
import {
  Personal,
  Employment,
  Salary,
  BankDetails,
  Data,
  DocumentSchema,
} from "../types";
import {
  BankDetailsFetch,
  employeeDetails,
  employeeSave,
  enach,
  esign,
  fileUpload,
  fileUploadNew,
  getBenifit,
  insertReferences,
  PostalCodeApi,
  updateBulk,
  updateBulkAppraised,
  updateEmployment,
  updateLoanRequest,
  updateBenefitRequest,
  updateReferences,
} from "../utilities/request";
import { config } from "../config";
//import { Loan } from "./loan";
import { BenifitStatus } from "../utilities/status";
import usePrevious from "../utilities/previous";
import { TopHeader } from "./common/topHeader";
const { Paragraph, Text, Title } = Typography;
const { Link } = Anchor;
const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;
const { TabPane } = Tabs;
type RequiredMark = boolean | "optional";
export function Benefit() {
  const { Option } = Select;
  const [states, setStates] = useState<any>(null);
  const [docstates, setDocStates] = useState<any>([]);
  const [option, setOption] = useState("");
  const [docoption, setDocOption] = useState("");
  const [docpresent, setDocPresent] = useState(true);
  //
  const { innerWidth: width, innerHeight: height } = window;
  const [visible, setVisible] = useState(false);
  const [result, setResult] = useState({});
  const [temp, setTemp] = useState<Data>({});
  const search = useLocation().search;
  const [salary, setSalary] = useState<Array<Salary> | undefined>(undefined);
  const [employment, setEmployment] = useState<Employment>({});
  const [bank, setBank] = useState<BankDetails>({});
  const [personal, setPersonal] = useState<Personal>({});
  const [documents, setDocument] = useState<Array<DocumentSchema>>([]);

  // personal
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  // permanent address
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const prevpincode = usePrevious(pincode);
  // temporary address

  const [address_1, setAddress_1] = useState("");
  const [state_1, setState_1] = useState("");
  const [city_1, setCity_1] = useState("");
  const [pincode_1, setPincode_1] = useState("");
  const prevpincode_1 = usePrevious(pincode_1);
  // suggested loan amount and period

  const [suggested_amount, setSuggestedAmount] = useState("");
  const [suggested_period, setSuggestedPeriod] = useState("");

  const [loan_purpose, setLoanPurpose] = useState("");
  const [fathername, setFatherName] = useState("");
  const [dob, setDob] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [temp_mobile, setTempMobile] = useState("");
  const [gender, setGender] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [pan, setPan] = useState("");
  const [aadhaar, setAadhaar] = useState("");
  // bank
  const [ifsc, setIfsc] = useState("");
  const [account, setAccount] = useState("");
  //employment
  const [employerName, setEmployerName] = useState("");
  const [designation, setDesignation] = useState("");
  const [empsalary, setEmpSalary] = useState("");
  const [workLocation, setWorkLocation] = useState("");
  const [doj, setDoj] = useState("");
  const [department, setDepartMent] = useState("");
  const [job_type, setJobType] = useState("permanent");
  //
  // const loan_id = new URLSearchParams(search).get("loan_id");
  // const employee_id = new URLSearchParams(search).get("employee_id");
  const request_id = new URLSearchParams(search).get("id");
  const type = "benefit";
  //
  const [salaryData, setSalaryData] = useState([]);
  const [loanStatus, setLoanStatus] = useState(true);
  //
  const [opsId, setOpsId] = useState("");
  const [internalEmpId, setInternalEmpId] = useState(null);
  const [active, setActive] = useState("1");
  const [references, setReferences] = useState([]);
  const [currentDocument, setCurrentDocument] = useState<DocumentSchema>({});
  // should call employee api
  const [callEmployee, setcallEmployee] = useState<Number>(Math.random);
  //should call loan api
  const [callLoan, setcallLoan] = useState<Number>(Math.random);
  // button
  //  modal
  const [isModalVisible, setIsModalVisible] = useState(false);
  // form
  const [newref, setNewReference] = useState(false);
  const [current_ref, setCurrenRef] = useState<any>({});
  const [form] = Form.useForm();
  const [requiredMark, setRequiredMarkType] =
    useState<RequiredMark>("optional");

  const onRequiredTypeChange = ({
    requiredMarkValue,
  }: {
    requiredMarkValue: RequiredMark;
  }) => {
    setRequiredMarkType(requiredMarkValue);
  };

  const handleCancel = () => {
    setNewReference(false);
    setIsModalVisible(false);
  };
  // form relation
  const [ref_name, setRefName] = useState("");
  const [ref_address, setRefAddress] = useState("");
  const [ref_whatsapp_no, setRefMobileNo] = useState<any>(null);
  const [ref_relation, setRefRelation] = useState("");
  const [ref_verified, setRefVerified] = useState(false);

  // bank details

  const [bank_name, setBankName] = useState("");
  const [bank_branch, setBankBranch] = useState("");
  const [bank_state, setBankState] = useState("");

  // drawer
  const [isDrawer, setIsDrawer] = useState(false);
  const showDrawer = () => {
    setIsDrawer(true);
  };
  const onClose = () => {
    setIsDrawer(false);
  };

  const props = {
    name: "file",
    action: `${config.base_url}/utils/upload`,
    headers: {
      authorization: "authorization-text",
    },
    async onChange(info: any) {
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        let data = {
          document_type: currentDocument.document_type,
          document_url: info.file.response.Location,
          is_verified: currentDocument.is_verified,
          emp_id: internalEmpId,
        };
        setVisible(false);
        {
          // @ts-ignore
          await fileUpload(data, currentDocument.id);
        }
        setVisible(true);
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      } else {
        // message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const handleOk = async () => {
    if (newref) {
      let data = {
        address: ref_address,
        is_verified: ref_verified,
        whatsapp_no: ref_whatsapp_no,
        name: ref_name,
        relation: ref_relation,
        request_id: request_id,
      };
      setIsModalVisible(false);
      setVisible(false);
      await insertReferences(data);
      setNewReference(false);
      setcallLoan(Math.random);
    } else {
      handleRefUpdate();
    }
  };
  const handleRefUpdate = async () => {
    setIsModalVisible(false);
    let data = {
      address: ref_address,
      whatsapp_no: ref_whatsapp_no,
      name: ref_name,
      relation: ref_relation,
      is_verified: ref_verified,
    };

    const result = await updateReferences(data, current_ref["id"]);
    setcallLoan(Math.random);

    setRefAddress("");
    setRefMobileNo("");
    setRefName("");
    setRefRelation("");
    setRefVerified(false);
  };
  const propsNewDocument = {
    name: "file",
    action: `${config.base_url}/utils/upload`,
    headers: {
      authorization: "authorization-text",
    },
    async onChange(info: any) {
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        let data = {
          document_type: docoption,
          document_url: info.file.response.Location,
          is_verified: true,
          emp_id: internalEmpId,
        };
        setVisible(false);
        {
          // @ts-ignore
          await fileUploadNew(data, employee_id);
        }
        setcallEmployee(Math.random);
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      } else {
        // message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  function callback(key: any) {
    setActive(key);
    console.log(key);
  }
  async function onChangeDoc(element: any, verify: boolean) {
    let data = {
      is_verified: verify,
      document_type: element.document_type,
      document_url: element.document_url,
    };
    try {
      const result = await fileUpload(data, element.id);
      setVisible(false);
      setcallEmployee(Math.random);
    } catch (err) { }
  }

  async function onChangeRef(element: any, verify: boolean) {
    let data = {
      is_verified: verify,
    };
    setVisible(false);
    try {
      const result = await updateReferences(data, element.id);
      setcallLoan(Math.random);
    } catch (err) { }
  }

  const columns = [
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Payout Date",
      dataIndex: "payout_date",
      key: "payout_date",
    },
  ];
  async function updateLoan(key: string) {
    try {
      setVisible(false);
      let data = {
        first_name: first_name,
        last_name: last_name,
        address: address,
        state: state,
        city: city,
        pincode: pincode,
        father_name: fathername,
        dob: dob,
        contact_no: mobile,
        contact_no_2: temp_mobile,
        gender: gender,
        marital_status: maritalStatus,
        pan: pan,
        ifsc_code: ifsc,
        account_no: Number(account),
        company_name: employerName,
        aadhaar: aadhaar,
        email_id: email,
        address_1: address_1,
        pincode_1: pincode_1,
        state_1: state_1,
        city_1: city_1,
      };
      {
        // @ts-ignore
        const result = await employeeSave(data, internalEmpId);
        setcallEmployee(Math.random);
      }
    } catch (err) { }
  }
  async function onChange(checked: any) {
    setLoanStatus(checked);
  }
  async function updateBenefitStatus(type: any) {
    setVisible(false);
    if (type == "benefit") {
      let request_data = {
        request_status: option,
        purpose: loan_purpose
      };
      const updateloanrequest = await updateBenefitRequest(
        request_id,
        request_data
      );
      window.location.reload();
    }
  }

  async function updateBenefit(type: any) {
    setVisible(false);
    if (type == "benefit") {
      let request_data = {
        purpose: loan_purpose,
        request_status: option
      };
      const updateloanrequest = await updateBenefitRequest(
        request_id,
        request_data
      );
      window.location.reload();
    }
  }

  async function updateEmploymentSingle(key: string) {
    try {
      setVisible(false);
      let data = {
        date_of_joining: doj,
        designation: designation,
        monthly_salary: Number(empsalary),
        work_location: workLocation,
        job_type: job_type,
        department: department,
      };
      {
        // @ts-ignore
        const result = await updateEmployment(key, data);
        setcallEmployee(Math.random);
      }
    } catch (err) { }
  }

  const refCols: any = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Phone",
      dataIndex: "whatsapp_no",
      key: "whatsapp_no",
    },

    {
      title: "Relation",
      dataIndex: "relation",
      key: "relation",
    },

    {
      title: "Verified",
      render: (text: any, record: any) => {
        return record.is_verified ? (
          <Tooltip title="references verified">
            <CheckCircleFilled style={{ fontSize: "30px" }} />
          </Tooltip>
        ) : (
          <Tooltip title="reference not verified">
            <CloseCircleFilled style={{ fontSize: "30px" }} />
          </Tooltip>
        );
      },
      key: "is_verified",
    },
    {
      title: "Update",
      render: (text: any, record: any) => {
        return (
          <Button
            type="primary"
            onClick={() => {
              updateModalSelectedReference(record);
            }}
          >
            Update
          </Button>
        );
      },
      key: "update",
      dataIndex: "update",
    },
  ];

  async function updateModalSelectedReference(element: any) {
    setCurrenRef(element);
    setRefAddress(element.address);
    setRefMobileNo(element.whatsapp_no);
    setRefName(element.name);
    setRefRelation(element.relation);
    setRefVerified(element.is_verified);
    setIsModalVisible(true);
  }
  async function updateValues() {
    setCurrenRef({});
    setRefAddress("");
    setRefMobileNo("");
    setRefName("");
    setRefRelation("");
    setRefVerified(false);
  }
  function parseDocuments(docs: Array<DocumentSchema>) {
    docs.sort((a: any, b: any) => {
      if (a.document_type > b.document_type) {
        return 1;
      } else {
        return -1;
      }
    });
    let counter: number = 0;
    let key: any = "";
    if (docs[0]) {
      key = docs[0]["document_type"];
    }

    docs.forEach((el: any) => {
      let status = el["document_type"];
      if (status == key) {
        counter++;
      } else {
        counter = 1;
      }
      el["document_name"] = `${el["document_type"] + " " + counter}`;
      key = status;
    });
    return docs;
  }
  function RouteGuard() {
    let check: boolean = false;
    if (localStorage.getItem("token") && localStorage.getItem("user")) {
      check = true;
    }
    return check;
  }
  useEffect(() => {
    (async () => {
      {
        const routeguard: boolean = RouteGuard();
        console.log("route gaurd is " + routeguard)
        //debugger;
        if (routeguard) {
          //@ts-ignore
          // debugger;
          if (type == "operations") {
            //setStates(OperationsStatus);
          } else if (type == "benefit") {
            setStates(BenifitStatus);
          }
          //@ts-ignore
          // else if (type == "benefit") {
          //   //setStates(OperationsStatus);
          // }
          const products = await getBenifit(request_id);
          console.log("references are ", products);
          setTemp(products);
          setOption(String(products["request_status"]))
          // set suggested amount
          // setSuggestedAmount(String(products["suggested_amount_requested"]));
          // setSuggestedPeriod(String(products["suggested_loan_period"]));
          setLoanPurpose(String(products["purpose"]));
          // setReferences(products["references"]);
          // console.log("references are ", products["references"]);
          setVisible(true);
        } else {
          window.location.href = "/no_cred";
        }
      }
    })();
  }, [callLoan]);

  // useEffect(() => {
  //   (async () => {
  //     {
  //       // @ts-ignore

  //       //const res = await employeeDetails(employee_id);
  //       // call bank api

  //       // bank api
  //       const json: any = res;
  //       const bank = await BankDetailsFetch(json["bank_details"]["ifsc_code"]);
  //       if (bank) {
  //         setBankName(bank["BANK"]);
  //         setBankBranch(bank["BRANCH"]);
  //         setBankState(bank["STATE"]);
  //       }
  //       setInternalEmpId(json["id"]);
  //       setSalary(modifiedSalary(json["salaries"]));
  //       setEmployment(json["employment"]);
  //       setBank(json["bank_details"]);

  //       setDocument(parseDocuments(json["documents"]));
  //       // documents
  //       CheckDocuments(json["documents"]);
  //       // set Personal Details
  //       setFirstName(json["first_name"]);
  //       setLastName(json["last_name"]);
  //       setAddress(json["address"]);
  //       // setState(json["state"]);
  //       // setCity(json["city"]);
  //       setPincode(json["pincode"]);
  //       setAddress_1(json["address_1"]);
  //       // setState_1(json["state_1"]);
  //       // setCity_1(json["city_1"]);
  //       setPincode_1(json["pincode_1"]);
  //       setMaritalStatus(json["marital_status"]);
  //       setGender(json["gender"]);
  //       setMobile(json["mobile"]);
  //       setTempMobile(json["mobile_2"]);
  //       setPan(json["pan_no"]);
  //       setAadhaar(json["aadhaar"]);
  //       setFatherName(json["father_name"]);
  //       setDob(json["dob"]);
  //       setEmail(json["email_id"]);
  //       setPersonal(json);
  //       // set ops id
  //       setOpsId(json["ops_id"]);
  //       // set Bank
  //       setIfsc(json["bank_details"]["ifsc_code"]);
  //       setAccount(String(json["bank_details"]["account_no"]));
  //       // set Employment
  //       setEmployerName(json["employment"]["employer_name"]);
  //       setDesignation(json["employment"]["designation"]);
  //       // setEmpSalary(
  //       //   String(calculateAverageSalary(json["salaries"]))
  //       // );
  //       setDoj(json["employment"]["date_of_joinig"]);
  //       setWorkLocation(json["employment"]["work_location"]);
  //       setDepartMent(json["employment"]["department"]);
  //       setJobType(
  //         json["employment"]["job_type"].length == 0
  //           ? "permanent"
  //           : json["employment"]["job_type"]
  //       );
  //       // set Visible
  //       setVisible(true);
  //     }
  //   })();
  // }, [callEmployee]);

  // useEffect(() => {
  //  (async () => {
  //     if (prevpincode !== pincode) {
  //       const { district, state } = await PostalCodeApi(pincode);
  //       setState(state);
  //       setCity(district);
  //     }
  //     if (prevpincode_1 !== pincode_1) {
  //       const { district, state } = await PostalCodeApi(pincode_1);
  //       setState_1(state);
  //       setCity_1(district);
  //     }
  //   })()
  // }, [pincode, pincode_1]);

  function handleChange(value: any) {
    setOption(value);
  }
  function handleChangeDocument(value: any) {
    setDocOption(value);
  }

  function modifiedSalary(data: typeof modifiedSalary | any) {
    for (let index = 0; index < data.length; index++) {
      let today = new Date(data[index].payout_date);
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0");
      let yyyy = today.getFullYear();
      data[index].payout_date = dd + "/" + mm + "/" + yyyy;
    }
    return data;
  }
  function CheckDocuments(data: any) {
    let array = [
      "pan",
      "aadhaar front",
      "aadhaar back",
      "bank statement",
      "selfie",
      "current address proof",
      "salary slip",
      "passbook",
      "permanent address front",
      "permanent address back",
    ];
    let result: any = [];
    let doc_array: any = [];
    data.forEach((e: any) => {
      doc_array.push(e.document_type);
    });
    array.forEach((el: any) => {
      // if (!doc_array.includes(el))
      result.push({ type: el });
    });
    setDocStates(result);
    if (result.length == 0) {
      setDocPresent(false);
    }
    console.log("doc states is ", result, docstates);
  }
  function returnName(name: string) {
    let result = "";
    name.split("_").forEach((el) => {
      result = result + " " + el.toUpperCase();
    });
    return result;
  }

  return (
    <div
      style={{
        backgroundColor: "#f0f0f0",
        height: height,
        width: width * 0.9,
        overflowY: "scroll",
      }}
    >
      <TopHeader></TopHeader>
      {visible == true ? (
        <div>
          <Card bordered={true} style={{ width: width * 0.8, margin: "3%" }}>
            <Row>
              <Col span={18}>
                <p
                  style={{
                    fontFamily: "Helvetica Neue",
                    fontSize: "14px",
                    marginLeft: "2%",
                    marginTop: "1%",
                    color: "grey",
                  }}
                >

                  {"Benefit Section"}
                </p>
              </Col>
              <Col span={6} style={{ marginBottom: "2%" }}>
                <Button
                  shape="round"
                  onClick={showDrawer}
                  style={{ width: "80%" }}
                >
                  Update
                </Button>
              </Col>
            </Row>
            <Divider></Divider>
            <Row style={{ padding: "2%" }}>
              <Col span={6} style={{ marginBottom: "2%" }}>
                {/* <Select
                    defaultValue={temp?.["request_status"]}
                      style={{ width: "80%", marginRight: "10%" }}
                      onChange={handleChange}
                    >
                      {states.map((element: any) => {
                        return <Option value={element}>{element}</Option>;
                      })}
              </Select> */}
                <Statistic
                  valueStyle={{ fontSize: "14px" }}
                  title="Request status:"
                  value={temp?.["request_status"]}
                />
              </Col>
              {/* {type == "benefit" ? (
                <Col span={6} style={{marginBottom:"2%"}}>
                  <p style={{color:"#8c8c8c", marginBottom:"1%"}}>Suggested Period:</p>
                  <Text
                    style={{ fontSize: "14px"}}
                    editable={{
                      icon: <HighlightOutlined />,
                      tooltip: "click to edit text",
                      onChange: setSuggestedPeriod,
                    }}
                  >
                    {suggested_period}
                  </Text>
                </Col>
              ) : (
                ""
              )} */}

              {/* <Col span={6} style={{marginBottom:"2%"}}>
                <Statistic
                  title="Amount Requested:"
                  value={temp?.["amount_requested"]}
                  valueStyle={{ fontSize: "14px" }}
                />
              </Col> */}
              {/* {type == "benefit" ? (
                <Col span={6} style={{marginBottom:"2%"}}>
                  <p style={{color:"#8c8c8c", marginBottom:"1%"}}>Suggested Amount:</p>
                  <Text
                    style={{ fontSize: "14px" }}
                    editable={{
                      icon: <HighlightOutlined />,
                      tooltip: "click to edit text",
                      onChange: setSuggestedAmount,
                    }}
                  >
                    {suggested_amount}
                  </Text>
                </Col>
              ) : (
                ""
              )} */}
              <Col span={6} style={{ marginBottom: "2%" }}>
                <p style={{ color: "#8c8c8c", marginBottom: "1%" }}>Purpose:</p>
                <Text
                  style={{ fontSize: "14px" }}
                  editable={{
                    icon: <HighlightOutlined />,
                    tooltip: "click to edit text",
                    onChange: setLoanPurpose,
                  }}
                >
                  {loan_purpose}
                </Text>
              </Col>
              <Col span={6} style={{ marginBottom: "2%" }}>
                <Statistic
                  valueStyle={{ fontSize: "14px" }}
                  title="Channel:"
                  value={temp?.["channel"]}
                />
              </Col>
              <Col span={6} style={{ marginBottom: "2%" }}>
                <Statistic
                  valueStyle={{ fontSize: "14px" }}
                  groupSeparator=""
                  title="Mobile Number:"
                  value={temp?.["whatsapp_no"]}
                />
              </Col>
              <Col span={6} style={{ marginBottom: "2%" }}>
                <p style={{ color: "#8c8c8c", marginBottom: "1%" }}>Scheme:</p>
                <Text
                  style={{ fontSize: "14px" }}
                >
                  {temp["scheme"]|| "Govt Scheme"}
                </Text>
              </Col>
              <Col span={12} style={{ marginBottom: "2%" }}>
                <Statistic
                  valueStyle={{ fontSize: "14px" }}
                  title="Full Name:"
                  value={temp?.["full_name"]}
                />
              </Col>
              <Col span={2} style={{ padding: "2%" }}>
                <Button
                  type="primary"
                  size="large"
                  onClick={() => {
                    updateBenefit("benefit");
                  }}
                >
                  Save Data
                </Button>
              </Col>

              {/* <Col span={6} style={{marginBottom:"2%"}}>
                {type == "operations" ? (
                  <Statistic
                    title={"Request Status:"}
                    value={temp?.["request_status"]}
                    valueStyle={{ fontSize: "14px" }}
                  />
                ) : (
                  ""
                )}
              </Col> */}
            </Row>
            <Row>
              <Col span={20}></Col>
            </Row>


          </Card>

          <Card bordered={true} style={{ width: width * 0.8, margin: "3%", display: "none" }}>
            <Tabs
              onChange={callback}
              size="large"
              style={{}}
              activeKey={active}
            >
              <TabPane style={{ fontSize: "14px" }} tab="Personal" key="1">
                <Row>
                  <Col span={6} style={{ padding: "2%" }}>
                    <h6>
                      <b>First Name</b>
                    </h6>
                    <Text
                      style={{ fontSize: "14px" }}
                      editable={{
                        icon: <HighlightOutlined />,
                        tooltip: "click to edit text",
                        onChange: setFirstName,
                      }}
                    >
                      {first_name}
                    </Text>
                  </Col>
                  <Col
                    span={6}
                    style={{ padding: "2%", background: "#f5f5f5" }}
                  >
                    <h6>
                      <b>Last Name</b>
                    </h6>
                    <Text
                      style={{ fontSize: "14px" }}
                      editable={{
                        icon: <HighlightOutlined />,
                        tooltip: "click to edit text",
                        onChange: setLastName,
                      }}
                    >
                      {last_name}
                    </Text>
                  </Col>

                  <Col span={6} style={{ padding: "2%" }}>
                    <h6>
                      <b>Father Name</b>
                    </h6>
                    <Text
                      style={{ fontSize: "14px" }}
                      editable={{
                        icon: <HighlightOutlined />,
                        tooltip: "click to edit text",
                        onChange: setFatherName,
                      }}
                    >
                      {fathername}
                    </Text>
                  </Col>

                  <Col
                    span={6}
                    style={{ padding: "2%", background: "#f5f5f5" }}
                  >
                    <h6>
                      <b>Date of Birth</b>
                    </h6>
                    <Input
                      type="date"
                      value={dob.split("T")[0]}
                      onChange={(e) => {
                        setDob(e.target.value);
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col
                    span={6}
                    style={{ padding: "2%", background: "#f5f5f5" }}
                  >
                    <h6>
                      <b>Email</b>
                    </h6>
                    <Text
                      style={{ fontSize: "14px" }}
                      editable={{
                        icon: <HighlightOutlined />,
                        tooltip: "click to edit text",
                        onChange: setEmail,
                      }}
                    >
                      {email}
                    </Text>
                  </Col>

                  <Col span={6} style={{ padding: "2%" }}>
                    <h6>
                      <b>Mobile</b>
                    </h6>
                    <Text
                      style={{ fontSize: "14px" }}
                      editable={{
                        icon: <HighlightOutlined />,
                        tooltip: "click to edit text",
                        onChange: setMobile,
                      }}
                    >
                      {mobile}
                    </Text>
                  </Col>
                  <Col
                    span={6}
                    style={{ padding: "2%", background: "#f5f5f5" }}
                  >
                    <h6>
                      <b>Gender</b>
                    </h6>
                    <Text
                      style={{ fontSize: "14px" }}
                      editable={{
                        icon: <HighlightOutlined />,
                        tooltip: "click to edit text",
                        onChange: setGender,
                      }}
                    >
                      {gender}
                    </Text>
                  </Col>
                  <Col span={6} style={{ padding: "2%" }}>
                    <h6>
                      <b>Marital Status</b>
                    </h6>
                    <Text
                      style={{ fontSize: "14px" }}
                      editable={{
                        icon: <HighlightOutlined />,
                        tooltip: "click to edit text",
                        onChange: setMaritalStatus,
                      }}
                    >
                      {maritalStatus}
                    </Text>
                  </Col>
                </Row>

                <Row>
                  <Col span={6} style={{ padding: "2%" }}>
                    <h6>
                      <b>Pan Number</b>
                    </h6>
                    <Text
                      style={{ fontSize: "14px" }}
                      editable={{
                        icon: <HighlightOutlined />,
                        tooltip: "click to edit text",
                        onChange: setPan,
                      }}
                    >
                      {pan}
                    </Text>
                  </Col>
                  <Col
                    span={6}
                    style={{ padding: "2%", background: "#f5f5f5" }}
                  >
                    <h6>
                      <b>Aadhaar</b>
                    </h6>
                    <Text
                      style={{ fontSize: "14px" }}
                      editable={{
                        icon: <HighlightOutlined />,
                        tooltip: "click to edit text",
                        onChange: setAadhaar,
                      }}
                    >
                      {aadhaar}
                    </Text>
                  </Col>
                  <Col span={6} style={{ padding: "2%" }}>
                    <h6>
                      <b>Temporary Contact Number</b>
                    </h6>
                    <Text
                      style={{ fontSize: "14px" }}
                      editable={{
                        icon: <HighlightOutlined />,
                        tooltip: "click to edit text",
                        onChange: setTempMobile,
                      }}
                    >
                      {temp_mobile}
                    </Text>
                  </Col>
                  <Col span={6} style={{ padding: "2%" }}>
                    <h6>
                      <b>Employee ID (With Domain)</b>
                    </h6>
                    <Text style={{ fontSize: "14px" }}>
                      {temp["client_employee_id"]}
                    </Text>
                  </Col>
                </Row>
                <Row>
                  <Col span={6} style={{ padding: "2%" }}>
                    <h6>
                      <b>Employee ID (Without Domain)</b>
                    </h6>
                    <Text style={{ fontSize: "14px" }}>
                      {temp["client_employee_id"]?.includes("$")
                        ? temp["client_employee_id"]?.split("$")[0]
                        : ""}
                    </Text>
                  </Col>

                  <Col span={6} style={{ padding: "2%" }}>
                    <h6>
                      <b>Employee Operations ID </b>
                    </h6>
                    <Text style={{ fontSize: "14px" }}>{opsId}</Text>
                  </Col>
                </Row>
                <Row>
                  <Col span={20} style={{ padding: "2%" }}></Col>
                  <Col span={2} style={{ padding: "2%" }}>
                    <Button
                      type="primary"
                      size="large"
                      onClick={() => {
                        updateLoan("1");
                      }}
                    >
                      Save Data
                    </Button>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab="Bank and Financial" key="2">
                {/* // bank */}

                <Row>
                  <Col
                    span={6}
                    style={{ padding: "2%", background: "#f5f5f5" }}
                  >
                    <h6>
                      <b>IFSC Code</b>
                    </h6>
                    <Text style={{ fontSize: "14px" }}>{ifsc}</Text>
                  </Col>
                  <Col span={6} style={{ padding: "2%" }}>
                    <h6>
                      <b>Account Number</b>
                    </h6>
                    <Text
                      style={{ fontSize: "14px" }}
                      editable={{
                        icon: <HighlightOutlined />,
                        tooltip: "click to edit text",
                        onChange: setAccount,
                      }}
                    >
                      {account}
                    </Text>
                  </Col>

                  <Col
                    span={6}
                    style={{ padding: "2%", background: "#f5f5f5" }}
                  >
                    <h6>
                      <b>Bank Name</b>
                    </h6>
                    <Text style={{ fontSize: "14px" }}>{bank_name}</Text>
                  </Col>

                  <Col span={6} style={{ padding: "2%" }}>
                    <h6>
                      <b>Branch</b>
                    </h6>
                    <Text style={{ fontSize: "14px" }}>{bank_branch}</Text>
                  </Col>
                </Row>

                <Row>
                  <Col
                    span={6}
                    style={{ padding: "2%", background: "#f5f5f5" }}
                  >
                    <h6>
                      <b>State</b>
                    </h6>
                    <Text style={{ fontSize: "14px" }}>{bank_state}</Text>
                  </Col>
                </Row>
                <Row>
                  <Col span={20} style={{ padding: "2%" }}></Col>
                  <Col span={2} style={{ padding: "2%" }}>
                    <Button
                      type="primary"
                      size="large"
                      onClick={() => {
                        updateLoan("2");
                      }}
                    >
                      Save Data
                    </Button>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab="Documents" key="3">
                <ol>
                  {documents.map((element: any) => {
                    return (
                      <li>
                        <span>
                          <a
                            style={{ fontSize: "20px", margin: "2%" }}
                            target="_blank"
                            href={element.document_url}
                          >
                            {element.document_name.toUpperCase()}
                          </a>{" "}
                          {element.is_verified ? (
                            <Tooltip title="documents verified">
                              <CheckCircleFilled style={{ fontSize: "30px" }} />
                            </Tooltip>
                          ) : (
                            <Tooltip title="documents not verified">
                              <CloseCircleFilled style={{ fontSize: "30px" }} />
                            </Tooltip>
                          )}
                          {element.is_verified ? (
                            <Button
                              style={{ margin: "2%" }}
                              onClick={() => {
                                onChangeDoc(element, false);
                              }}
                            >
                              Cancel Verification
                            </Button>
                          ) : (
                            <Button
                              style={{ margin: "2%" }}
                              onClick={() => {
                                onChangeDoc(element, true);
                              }}
                            >
                              Approve Verification
                            </Button>
                          )}
                          <Upload {...props}>
                            <Button
                              icon={<UploadOutlined />}
                              onClick={() => {
                                setCurrentDocument(element);
                              }}
                            >
                              Click to Upload
                            </Button>
                          </Upload>
                        </span>
                      </li>
                    );
                  })}
                </ol>
                {docpresent == true ? (
                  <>
                    <Row>
                      <Col span={1}></Col>
                      <Col span={1}></Col>
                      <Col span={24} style={{ marginBottom: "" }}>
                        <Divider
                          style={{ fontFamily: "monospace", fontSize: "20px" }}
                        >
                          UPLOAD DOCUMENTS
                        </Divider>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={6}></Col>
                      <Col span={6}>
                        {" "}
                        <Select
                          defaultValue="Please select a document"
                          style={{ width: "100%" }}
                          onChange={handleChangeDocument}
                        >
                          {docstates.map((element: any) => {
                            return (
                              <Option value={element["type"]}>
                                {element["type"].toUpperCase()}
                              </Option>
                            );
                          })}
                        </Select>
                      </Col>
                      <Col span={2}></Col>
                      <Col span={6}>
                        <Upload {...propsNewDocument}>
                          <Button icon={<UploadOutlined />}>
                            Upload a new Document
                          </Button>
                        </Upload>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}
              </TabPane>
              <TabPane tab="Employment" key="4">
                <Row>
                  <Col span={6} style={{ padding: "2%" }}>
                    <h6>
                      <b>Employer Name</b>
                    </h6>
                    <Text style={{ fontSize: "14px" }}>{employerName}</Text>
                  </Col>
                  <Col
                    span={6}
                    style={{ padding: "2%", background: "#f5f5f5" }}
                  >
                    <h6>
                      <b>Designation</b>
                    </h6>
                    <Text
                      style={{ fontSize: "14px" }}
                      editable={{
                        icon: <HighlightOutlined />,
                        tooltip: "click to edit text",
                        onChange: setDesignation,
                      }}
                    >
                      {designation}
                    </Text>
                  </Col>
                  <Col span={6} style={{ padding: "2%" }}>
                    <h6>
                      <b>Average Salary</b>
                    </h6>
                    <Text
                      style={{ fontSize: "14px" }}
                      editable={{
                        icon: <HighlightOutlined />,
                        tooltip: "click to edit text",
                        onChange: setEmpSalary,
                      }}
                    >
                      {empsalary}
                    </Text>
                  </Col>
                  <Col
                    span={6}
                    style={{ padding: "2%", background: "#f5f5f5" }}
                  >
                    <h6>
                      <b>Work Location</b>
                    </h6>
                    <Text
                      style={{ fontSize: "14px" }}
                      editable={{
                        icon: <HighlightOutlined />,
                        tooltip: "click to edit text",
                        onChange: setWorkLocation,
                      }}
                    >
                      {workLocation}
                    </Text>
                  </Col>
                </Row>
                <Row>
                  <Col
                    span={6}
                    style={{ padding: "2%", background: "#f5f5f5" }}
                  >
                    <h6>
                      <b>Date of Joining</b>
                    </h6>

                    <Input
                      type="date"
                      value={doj.split("T")[0]}
                      onChange={(e) => {
                        setDoj(e.target.value);
                      }}
                    />
                  </Col>

                  <Col span={6} style={{ padding: "2%" }}>
                    <h6>
                      <b>Department</b>
                    </h6>
                    <Text
                      style={{ fontSize: "14px" }}
                      editable={{
                        icon: <HighlightOutlined />,
                        tooltip: "click to edit text",
                        onChange: setDepartMent,
                      }}
                    >
                      {department}
                    </Text>
                  </Col>

                  <Col
                    span={6}
                    style={{ padding: "2%", background: "#f5f5f5" }}
                  >
                    <h6>
                      <b>Job Type</b>
                    </h6>

                    <Text
                      style={{ fontSize: "14px" }}
                      editable={{
                        icon: <HighlightOutlined />,
                        tooltip: "click to edit text",
                        onChange: setJobType,
                      }}
                    >
                      {job_type}
                    </Text>
                  </Col>
                </Row>

                <Table
                  style={{ margin: "1%" }}
                  dataSource={salary}
                  columns={columns}
                />
                <Row>
                  <Col span={20} style={{ padding: "2%" }}></Col>
                  <Col span={2} style={{ padding: "2%" }}>
                    <Button
                      type="primary"
                      size="large"
                      onClick={() => {
                        updateEmploymentSingle(String(employment?.id));
                      }}
                    >
                      Save Data
                    </Button>
                  </Col>
                </Row>
              </TabPane>
              {/* <TabPane tab="Salaries" key="5">
                <Table dataSource={salary} columns={columns} />
              </TabPane> */}
              <TabPane tab="References" key="6">
                {/* <ol>
                  {references.map((element: any) => {
                    return (
                      <li>
                        <span>
                          <span style={{ fontSize: "14px", margin: "2%" }}>
                            {element.name.toUpperCase()}
                          </span>
                          <span style={{ fontSize: "14px", margin: "2%" }}>
                            {element.address.toUpperCase()}
                          </span>
                          <span style={{ fontSize: "14px", margin: "2%" }}>
                            {element.whatsapp_no.toUpperCase()}
                          </span>
                          <span style={{ fontSize: "14px", margin: "2%" }}>
                            {element.relation.toUpperCase()}
                          </span>
                          {element.is_verified ? (
                            <Tooltip title="references verified">
                              <CheckCircleFilled style={{ fontSize: "30px" }} />
                            </Tooltip>
                          ) : (
                            <Tooltip title="reference not verified">
                              <CloseCircleFilled style={{ fontSize: "30px" }} />
                            </Tooltip>
                          )}
                          {element.is_verified ? (
                            <Button
                              style={{ margin: "2%" }}
                              onClick={() => {
                                onChangeRef(element, false);
                              }}
                            >
                              Cancel Verification
                            </Button>
                          ) : (
                            <Button
                              style={{ margin: "2%" }}
                              onClick={() => {
                                onChangeRef(element, true);
                              }}
                            >
                              Approve Verification
                            </Button>
                          )}{" "}
                        </span>
                      </li>
                    );
                  })}
                </ol> */}
                <Table columns={refCols} dataSource={references} />
                <Row>
                  <Col span={1}>
                    {" "}
                    <Divider style={{ fontFamily: "", fontSize: "20px" }}>
                      {" "}
                      <span style={{ fontWeight: 340 }}>Upload Reference</span>
                      <PlusCircleTwoTone
                        style={{ margin: "5%", fontSize: "30px" }}
                        onClick={() => {
                          setNewReference(true);
                          updateValues();
                          setIsModalVisible(true);
                        }}
                      />
                    </Divider>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab="Address" key="7">
                <Row>
                  <Col
                    span={6}
                    style={{ padding: "2%", background: "#f5f5f5" }}
                  >
                    <h6>
                      <b>Permanent Address</b>
                    </h6>
                    <Text
                      style={{ fontSize: "14px" }}
                      editable={{
                        icon: <HighlightOutlined />,
                        tooltip: "click to edit text",
                        onChange: setAddress,
                      }}
                    >
                      {address}
                    </Text>
                  </Col>
                  <Col span={6} style={{ padding: "2%" }}>
                    <h6>
                      <b>State</b>
                    </h6>
                    <Text
                      style={{ fontSize: "14px" }}
                    >
                      {state}
                    </Text>
                  </Col>

                  <Col span={6} style={{ padding: "2%" }}>
                    <h6>
                      <b>District</b>
                    </h6>
                    <Text
                      style={{ fontSize: "14px" }}
                    >
                      {city}
                    </Text>
                  </Col>
                  <Col
                    span={6}
                    style={{ padding: "2%", background: "#f5f5f5" }}
                  >
                    <h6>
                      <b>Pincode</b>
                    </h6>
                    <Text
                      style={{ fontSize: "14px" }}
                      editable={{
                        icon: <HighlightOutlined />,
                        tooltip: "click to edit text",
                        onChange: setPincode,
                      }}
                    >
                      {pincode}
                    </Text>
                  </Col>
                </Row>

                <Row>
                  <Col
                    span={6}
                    style={{ padding: "2%", background: "#f5f5f5" }}
                  >
                    <h6>
                      <b>Temporary Address</b>
                    </h6>
                    <Text
                      style={{ fontSize: "14px" }}
                      editable={{
                        icon: <HighlightOutlined />,
                        tooltip: "click to edit text",
                        onChange: setAddress_1,
                      }}
                    >
                      {address_1}
                    </Text>
                  </Col>
                  <Col span={6} style={{ padding: "2%" }}>
                    <h6>
                      <b>State</b>
                    </h6>
                    <Text
                      style={{ fontSize: "14px" }}
                    >
                      {state_1}
                    </Text>
                  </Col>

                  <Col span={6} style={{ padding: "2%" }}>
                    <h6>
                      <b>District</b>
                    </h6>
                    <Text
                      style={{ fontSize: "14px" }}
                    >
                      {city_1}
                    </Text>
                  </Col>
                  <Col
                    span={6}
                    style={{ padding: "2%", background: "#f5f5f5" }}
                  >
                    <h6>
                      <b>Pincode</b>
                    </h6>
                    <Text
                      style={{ fontSize: "14px" }}
                      editable={{
                        icon: <HighlightOutlined />,
                        tooltip: "click to edit text",
                        onChange: setPincode_1,
                      }}
                    >
                      {pincode_1}
                    </Text>
                  </Col>
                </Row>
                <Row>
                  <Col span={20} style={{ padding: "2%" }}></Col>
                  <Col span={2} style={{ padding: "2%" }}>
                    <Button
                      type="primary"
                      size="large"
                      onClick={() => {
                        updateLoan("7");
                      }}
                    >
                      Save Data
                    </Button>
                  </Col>
                </Row>
              </TabPane>
            </Tabs>
          </Card>
          {type == "benefit" ? (
            <div style={{ padding: "3%", paddingRight: "8%" }}>
              {/* <Loan
                loan_id={loan_id}
                request_id={request_id}
                employee_id={employee_id}
              ></Loan> */}
            </div>
          ) : (
            ""
          )}

          <Modal
            title="Reference"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <Row>
              <Col span={24}>
                <Form
                  form={form}
                  layout="vertical"
                  initialValues={{ requiredMarkValue: requiredMark }}
                  onValuesChange={onRequiredTypeChange}
                  requiredMark={requiredMark}
                >
                  <Form.Item
                    label="Name"
                    required
                    tooltip="This is a required field"
                  >
                    <Input
                      placeholder="name"
                      type="text"
                      value={ref_name}
                      onChange={(e) => {
                        setRefName(e.target.value);
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Mobile No"
                    required
                    tooltip="This is a required field"
                  >
                    <Input
                      placeholder="mobile no"
                      type="number"
                      value={ref_whatsapp_no}
                      onChange={(e) => {
                        setRefMobileNo(e.target.value);
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Address"
                    required
                    tooltip="This is a required field"
                  >
                    <Input
                      placeholder="address"
                      type="text"
                      value={ref_address}
                      onChange={(e) => {
                        setRefAddress(e.target.value);
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Relation"
                    required
                    tooltip="This is a required field"
                  >
                    <Input
                      placeholder="relation"
                      type="text"
                      value={ref_relation}
                      onChange={(e) => {
                        setRefRelation(e.target.value);
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Verified"
                    required
                    tooltip="This is a required field"
                  >
                    <Checkbox
                      checked={ref_verified}
                      onChange={(e: any) => {
                        setRefVerified(e.target.checked);
                      }}
                    >
                      Is Verified
                    </Checkbox>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Modal>

          <Drawer
            title="Actions"
            placement="right"
            onClose={onClose}
            visible={isDrawer}
            width={450}
          >
            <>
              <Card>
                <Row style={{ margin: "5%" }}>
                  <Col span={16}>
                    <Select
                      defaultValue="Option"
                      style={{ width: "100%", marginRight: "10%" }}
                      onChange={handleChange}
                    >
                      {states.map((element: any) => {
                        return <Option value={element}>{element}</Option>;
                      })}
                    </Select>
                  </Col>
                  <Col span={8}>
                    <Button
                      type="primary"
                      style={{ marginTop: "", marginLeft: "10%" }}
                      shape="round"
                      onClick={() => {
                        updateBenefitStatus(type);
                      }}
                    >
                      Update Status
                    </Button>
                  </Col>
                </Row>
              </Card>

              {/* {type == "appraisal" ? (
                <Row>
                  <Col span={24}>
                    <Card style={{ margin: "1%", marginTop: "1%" }}>
                      <Button
                        shape="round"
                        type="primary"
                        style={{
                          marginTop: "5%",
                          marginBottom: "5%",
                          width: "100%",
                          background: "#40a9ff",
                        }}
                        onClick={() => {
                          eNach();
                        }}
                      >
                        Generate Enach
                      </Button>
                    </Card>
                  </Col>

                  <Col span={24}>
                    <Card style={{ margin: "1%", marginTop: "1%" }}>
                      <Button
                        shape="round"
                        type="primary"
                        style={{
                          marginTop: "5%",
                          marginBottom: "5%",
                          width: "100%",
                          background: "#40a9ff",
                        }}
                        onClick={() => {
                          eSign();
                        }}
                      >
                        Generate Esignature
                      </Button>
                    </Card>
                  </Col>
                </Row>
              ) : (
                ""
              )} */}
            </>
          </Drawer>
        </div>
      ) : (
        <Spin size="large" style={{ marginLeft: "40%", marginTop: "20%" }} />
      )}
    </div>
  );
}
