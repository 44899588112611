export const BenifitStatus: any = [
    "originated",
    "assigned_to_ops",
    "Flow not started",
    "Flow started",
    "Documentation pending",
    "Flow completed",
    "Not Interested",
    "Payment Initiated",
    "Under process",
    "Not responding",
    "Under process",
    "Payment Refunded",
    "Rejected",
    "Processed",
    "flow_complete",
    "originated",
    "assigned_to_ops",
    "data_complete",
    "data_verified",
    "payment_inititated",
    "payment_done",
    "shared_with_partner",
    "enrolled",
    "support",
    "rejected",
    "follow_up_1",
    "follow_up_2",
    "not_responded"
]

export const LeadStatus: any = [
    "originated",
    "assigned_to_ops",
    "Flow not started",
    "Flow started",
    "Documentation pending",
    "Flow completed",
    "Not Interested",
    "Payment Initiated",
    "Under process",
    "Not responding",
    "Under process",
    "Payment Refunded",
    "Rejected",
    "Processed",
    "flow_complete",
    "originated",
    "assigned_to_ops",
    "data_complete",
    "data_verified",
    "payment_inititated",
    "payment_done",
    "shared_with_partner",
    "enrolled",
    "support",
    "rejected",
    "follow_up_1",
    "follow_up_2",
    "not_responded"
]
export const OperationStatus: any = [
    "originated",
    "assigned_to_ops",
    "Flow not started",
    "Under process",
    "Payment Refunded",
    "Flow started",
    "Documentation pending",
    "Flow completed",
    "Not Interested",
    "Payment Initiated",
    "Under process",
    "Not responding",
    "Rejected",
    "Processed",
    "flow_complete",
    "originated",
    "assigned_to_ops",
    "data_complete",
    "data_verified",
    "payment_inititated",
    "payment_done",
    "shared_with_partner",
    "enrolled",
    "support",
    "rejected",
    "follow_up_1",
    "follow_up_2",
    "not_responded"
]

export const clientStatus: any = [
    "originated",
    "assigned_to_ops",
    "Flow not started",
    "Flow started",
    "Under process",
    "Payment Refunded",
    "Documentation pending",
    "Flow completed",
    "Not Interested",
    "Payment Initiated",
    "Under process",
    "Not responding",
    "Rejected",
    "Processed",
    "flow_complete",
    "originated",
    "assigned_to_ops",
    "data_complete",
    "data_verified",
    "payment_inititated",
    "payment_done",
    "shared_with_partner",
    "enrolled",
    "support",
    "rejected",
    "follow_up_1",
    "follow_up_2",
    "not_responded"
]

