export const filterOptionStatus = async (values: Array<string>) =>{
const array: any= ["empty"]
values.forEach((element: string)=>{
    array.push(
        {
            displayKey: element,
            displayName: element,
            test: function (filterValue: any, cellValue: any) {
              return cellValue == element;
            },
            hideFilterInput: true,
        },
    )
})

return array;
}