import axios from 'axios';
import { stat } from 'fs';
import moment from 'moment';
import { config } from '../config'



function getToken() {
  return { //@ts-ignore
    headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}` }
  }
}
export const employeeSave = async (data: any, id: string) => {
  try {
    const result = await axios.put(
      `${config.base_url}/employee/${id}`,
      data,
      getToken()
    );
    return result["data"]
  }
  catch (err) {
    window.location.href = "/no_cred"
  }

}
export const employeeDetails = async (id: string) => {
  try {
    const res = await axios.get(
      `${config.base_url}/employee/details/${id}`,
      getToken()
    );
    return res["data"];
  }
  catch (err) {
    window.location.href = "/no_cred"
  }

}
export const getConfig = async () => {
  try {
    const result = await axios.get(
      `${config.base_url}/utils/config`,
      getToken()
    );
    return result["data"]
  }
  catch (err) {
    window.location.href = "/no_cred"
  }

}
export const getBenefits = async (status:any,start_date:any,end_date:any, userid:any, user_role:any, page:any = 1,limit:any=50000) => {
  try{
    if (user_role == 'admin') {
      userid = 'all'
    }
    const result = await axios.get(
      `${config.base_url}/benefit-request?status=${status}&limit=${limit}&page=${page}&start_date=${start_date}&end_date=${moment(end_date).add(1, 'days').format("YYYY-MM-DD")}&userid=${userid}`,
      getToken()
    );
    //console.log(result)
    return result["data"]
  }
  catch(err){

    window.location.href = "/no_cred"

  }
  
}

export const getPolicies = async () => {
  try{
    const result = await axios.get(
      `${config.base_url}/insurance`,
      getToken()
    );
    return result["data"]
  }
  catch(err){

    window.location.href = "/no_cred"

  }
  
}
export const getLoanswithStatus = async (status: string) => {
  try {
    const result = await axios.get(
      `${config.base_url}/loan?page=1&limit=10&status=${status}`,
      getToken()
    );
    return result["data"]
  }
  catch (err) {
    window.location.href = "/no_cred"
  }

}
export const updateBulk = async (data: any) => {
  try {
    const result = await axios.post(
      `${config.base_url}/benefit-request/updateBulk`, data,
      getToken()
    );
    return result["data"]
  }
  catch (err) {
    window.location.href = "/no_cred"
  }

}
export const updateBulkAppraised = async (data: any) => {
  try {
    const result = await axios.put(
      `${config.base_url}/loan/appraised/updateBulk`, data,
      getToken()
    );
    return result["data"]
  }
  catch (err) {
    window.location.href = "/no_cred"
  }

}
export const appraisal = async (current_page: number, size: number, status: Array<string>, userid: any, start_date: string, end_date: string) => {
  try {
    if (userid == 1) {
      userid = 'all'
    }
    current_page = 1
    size = 50000
    const result = await axios.get(
      `${config.base_url}/loan/appraised/all?page=${current_page}&limit=${size}&status=${status}&userid=${userid}&start_date=${start_date}&end_date=${end_date}`,
      getToken()
    );
    return result["data"]
  }
  catch (err) {
    window.location.href = "/no_cred"
  }
 
}
export const enach = async (data: any) => {
  try {
    const result = await axios.post(
      `${config.base_url}/loan/create-sources`, data, getToken()
    );
    return result["data"]
  }
  catch (err) {
    window.location.href = "/no_cred"
  }
 
}
export const esign = async (data: any) => {
  try {
    const result = await axios.post(
      `${config.base_url}/loan/create-esign`, data, getToken()
    );
    return result["data"]
  }
  catch (err) {
    window.location.href = "/no_cred"
  }
 
}
export const fileUpload = async (data: any, document_id: number) => {
  try {
    const result = await axios.put(
      `${config.base_url}/employee/document/${document_id}`, data, getToken()
    );
    return result["data"];
  }
  catch (err) {
    window.location.href = "/no_cred"
  }

}

export const fileUploadNew = async (data: any, employee_id: number) => {
  try {
    const result = await axios.post(
      `${config.base_url}/employee/document`, data, getToken()
    );
    return result["data"];
  }
  catch (err) {
    window.location.href = "/no_cred"
  }
 
}

export const updateReferences = async (data: any, document_id: number) => {
  try {
    const result = await axios.put(
      `${config.base_url}/loan/reference/${document_id}`, data, getToken()
    );
    return result["data"];
  }
  catch (err) {
    window.location.href = "/no_cred"
  }

}

export const insertReferences = async (data: any) => {
  try {
    const result = await axios.post(
      `${config.base_url}/loan/reference-insert`, data, getToken()
    );
    return result["data"];
  }
  catch (err) {
    window.location.href = "/no_cred"
  }
 
}

export const getBenifit = async (request_id: number) => {
  try {
    const result = await axios.get(
      `${config.base_url}/benefit-request/${request_id}`, getToken()
    );
    return result["data"];
  }
  catch (err) {
    window.location.href = "/no_cred"
  }

}
export const updateEmployment = async (employee_id: number, data: any) => {
  try {
    const result = await axios.put(
      `${config.base_url}/employee/employment/${employee_id}`,
      data,
      getToken()
    );
    return result["data"];
  }
  catch (err) {
    window.location.href = "/no_cred"
  }

}

export const updateAllRowsConstant = async (data: any) => {
  try {
    const result = await axios.put(
      `${config.base_url}/repayment-schedule`,
      data,
      getToken()
    );
    return result["data"];
  }
  catch (err) {
    window.location.href = "/no_cred"
  }

}

export const getAuth = async (username: string, password: string) => {
  try {
    const result = await axios.post(
      `${config.base_url}/auth/login`,
      {
        username,
        password
      }
    );
    return result["data"];
  }
  catch (err) {
    return "error"
  }


}

export const getOriginalLoanData = async (loan_id: any) => {
  try {
    const result = await axios.get(
      `${config.base_url}/loan/appraised-loan/${loan_id}`,
      getToken()
    );
    return result["data"];
  }
  catch (err) {
    window.location.href = "/no_cred"
  }

}

export const updateLoanRequest = async (loan_request_id: any, data: any) => {
  try {

  }
  catch (err) {
    window.location.href = "/no_cred"
  }
  const result = await axios.put(
    `${config.base_url}/loan/${loan_request_id}`,
    data,
    getToken()
  );
  return result["data"];
}


export const updateBenefitRequest = async (request_id: any, data: any) => {
  try {

  }
  catch (err) {
    window.location.href = "/no_cred"
  }
  const result = await axios.put(
    `${config.base_url}/benefit-request/${request_id}`,
    data,
    getToken()
  );
  return result["data"];
}
export const getUsers = async () => {
  const result = await axios.get(
    `${config.base_url}/user`,
    getToken()
  );
  return result["data"];
}

export const updateBulkUser = async (data: any) => {
  try {
    const result = await axios.post(
      `${config.base_url}/benefit-request/assignUser`, data,
      getToken()
    );
    return result["data"]
  }
  catch (err) {
    window.location.href = "/no_cred"
  }

}

export const updateBulkLoanAssignUser = async (data: any) => {
  try {
    const result = await axios.put(
      `${config.base_url}/loan/appraisal/assignUser`, data,
      getToken()
    );
    return result["data"]
  }
  catch (err) {
    window.location.href = "/no_cred"
  }

}

export const createExport = async (data: any) => {
  try {
    const result = await axios.post(
      `${config.base_url}/loan/utils/create-export?company=${data}`, getToken(), { responseType: 'blob', }
    );
    const url = window.URL.createObjectURL(new Blob([result.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${new Date().toISOString()}.csv`); //or any other extension
    document.body.appendChild(link);
    link.click();
    return "success"
  }
  catch (err) {
    window.location.href = "/no_cred"
  }

 
}


export const BankDetailsFetch = async (ifsc: any) => {

  try {
    const result = await axios.get(`https://ifsc.razorpay.com/${ifsc}`);
    return result["data"];
  }
  catch (err) {
    return {
      "BANK": "",
      "BRANCH": "",
      "STATE": ""
    }
  }

}

export const PostalCodeApi = async (pincode:string) =>{

  try{
    const result = await axios.get(`https://api.postalpincode.in/pincode/${pincode}`)
    let data = result["data"][0]["PostOffice"][0];
    return {
      district: data["District"],
      state: data["State"]
    }

  }
  catch(err){
    return {
      district: "",
      state: ""
    }
  }

}






