import { Row, Col, Tooltip } from "antd";
import { LogoutOutlined } from "@ant-design/icons";

export const  TopHeader = ()=>{
    return   <Row style={{ background: "white", padding: "1%" }}>
    <Col span={2}></Col>
    <Col span={20}></Col>
    <Col span={2}>
      <Tooltip title="Logout">
        <LogoutOutlined
          style={{
            fontSize: "20px",
            marginTop: "10%",
            cursor: "pointer",
          }}
          onClick={()=>{
            localStorage.clear();
            window.location.href = "/";
          }}
        />
      </Tooltip>
    </Col>
  </Row>

}