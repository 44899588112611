

// https://ecea-122-161-64-151.ngrok.io/loan/create-export
//const dev_url ="http://ec2-13-126-10-102.ap-south-1.compute.amazonaws.com:3000"
const dev_url ="http://ec2-15-206-149-36.ap-south-1.compute.amazonaws.com:3000"
//const dev_url = "http://ec2-65-1-147-182.ap-south-1.compute.amazonaws.com:3000"
const prod_url = "https://api-non-credit.entitled.co.in"

export const config: any = {
    "base_url":process.env.REACT_APP_STAGE === 'production' ? prod_url : dev_url
}