import { Row, Col, Tooltip } from "antd";
import { LogoutOutlined, CloseCircleFilled } from "@ant-design/icons";

export const  NoCredentials = ()=>{
    return   <Row style={{}}>
    <Col span={8}></Col>
    <Col span={8}>
        <h5 style={{textAlign:"center",marginLeft:"23%", marginTop:"10%"}}>You are unauthorized to use this resource </h5>
    <CloseCircleFilled  style={{fontSize:"50px", color:"red", marginLeft:"57%", marginTop:"5%"}}/>
    <p  style={{textAlign:"center",marginLeft:"23%", marginTop:"7%"}}><a href="/login">Click here to go back to  Login</a></p>

    </Col>
   
  </Row>

}