import { Layout, Menu, Breadcrumb, Row, Col, Tooltip } from "antd";
import Entitled from "../assets/ent-logo-min.png";
import {
  UserOutlined,
  LaptopOutlined,
  NotificationOutlined,
  LoginOutlined,
  LogoutOutlined,
  DashboardTwoTone,
  DashboardFilled,
  DashboardOutlined,
  BankOutlined,
  LayoutOutlined
} from "@ant-design/icons";
import {
  useHistory,
  Link,
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
// import Originated from "./originated";
import { Lead } from "./leads";
import { Demo } from "./login";
import { LeadRequest } from "./client/leadRequest";
import { Benefit } from "./benefit"
import { Operation } from "./operations"
import { Policy } from "./policy"
import { NoCredentials } from "./common/noCredentials";
import { useLayoutEffect, useState, useEffect } from "react";
// import OpsReview from "./opsReview";
// import Appraisal from "./appraisal";
// import IncomingRequest from "./lineOfCredit/incomingRequest";
// import LineOfCredit from "./lineOfCredit/lineOfCredit";
// import Drawdowns from "./lineOfCredit/drawdowns";
// import { LocDetails } from "./lineOfCredit/locDetails";

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;
const { innerWidth: width, innerHeight: height } = window;

export function Sidebar() {
  const [width, setWidth] = useState(window.innerWidth);
  const [type, setType] = useState("");
  const [user, setUser] = useState("");
  const [height, setHeight] = useState(window.innerHeight);
  const [collapsed, setCollapsed] = useState(false);
  const setSize = () => {
    let path = window.location.pathname;
    switch (path) {
      case '/leads':
        return ["1"]
      case '/ops_review':
        return ["2"]
      // case '/appraisal':
      //   return ["3"]
      case '/policy':
        return ["4"]
      // case '/loc':
      //   return ["5"]
      // case '/loc_drawdowns':
      //   return ["6"]
    }
  }
  const [selectedKey, setKey] = useState<any>(setSize());
  function updateDimension() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }
  function onCollapse() {
    setCollapsed(!collapsed);
  }
  useLayoutEffect(() => {
    window.addEventListener("resize", updateDimension);
    updateDimension();

    return () => window.removeEventListener("resize", updateDimension);
  }, []);

  useEffect(() => {
    setType("admin")
    let userObject: any = localStorage.getItem("user");
    setUser(JSON.parse(userObject))
    console.log(user)
  }, [])
  const pathname = window.location.pathname;

  return (
    <Router>
      {pathname !== "/login" && pathname !== "/no_cred" ? (
        <>
          <Sider
            style={{ width: width * 0.1, height: height }}
            className="site-layout-background"
          // collapsible collapsed={collapsed} onCollapse={onCollapse}
          >
            <p
              style={{
                fontSize: "15px",
                textAlign: "center",
                fontWeight: 500,
                color: "white",
                marginLeft: "3%",
                marginRight: "3%",
                marginTop: "3%",
                padding: "5%",
                background: "#1f1f1f",
              }}
            >
              Entitled
            </p>
            <Menu
              mode="inline"
              defaultSelectedKeys={selectedKey}
              defaultOpenKeys={["sub1", "sub2"]}
              style={{ height: "100%", borderRight: 0 }}
              theme="dark"
            >


              {//@ts-ignore
                user["role"] == "admin" || user["role"] == "operations" ? <SubMenu key="sub1" icon={<BankOutlined />} title="Benefits">
                  <Menu.Item key="1">
                    <Link to="/leads">Leads</Link>
                  </Menu.Item>
                  <Menu.Item key="2">
                    <Link to="/ops_review">Operations</Link>
                  </Menu.Item>
                </SubMenu> : ""}
              {//@ts-ignore
                user["role"] == "admin" || user["role"] == "operations" ? <SubMenu key="sub1" icon={<BankOutlined />} title="Insurance">
                  <Menu.Item key="4">
                    <Link to="/policy">Policies</Link>
                  </Menu.Item>
                </SubMenu> : ""}
              {//@ts-ignore
                user["role"] == "client" ? <SubMenu key="sub1" icon={<BankOutlined />} title="Benefits">
                  <Menu.Item key="3">
                    <Link to="/lead_request">Leads</Link>
                  </Menu.Item>
                </SubMenu> : ""}
            </Menu>
          </Sider>
        </>
      ) : (
        ""
      )}

      <Content
        style={{
          width: pathname !== "/login" ? width * 0.9 : width,
          height: height * 1.1,
        }}
      >
        <Switch>
          <Route exact path="/">
            <Redirect to="/login" />
          </Route>
          <Route exact path="/login">
            <Demo></Demo>
          </Route>
          <Route exact path="/leads">
            <Lead></Lead>
          </Route>
          <Route exact path="/ops_review">
            <Operation></Operation>
          </Route>
          <Route exact path="/benifit">
            <Benefit></Benefit>
          </Route>
          <Route exact path="/no_cred">
            <NoCredentials />
          </Route>
          <Route exact path="/lead_request">
            <LeadRequest />
          </Route>
          <Route exact path="/policy">
            <Policy />
          </Route>
        </Switch>
      </Content>
    </Router>
  );
}
