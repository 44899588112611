import {
    Row,
    Col,
    Divider,
    Card,
    Pagination,
    Statistic,
    Spin,
    Descriptions,
    Badge,
    Tooltip,
    Button,
    Select,
    Typography,
    Drawer,
    message,
    DatePicker,
    Input
  } from "antd";

  import moment from "moment";
  import React from "react";
  import { render } from "react-dom";
  import { BenifitStatus,LeadStatus,OperationStatus } from "../utilities/status";
  import { AgGridColumn, AgGridReact } from "ag-grid-react";
  import { useEffect, useState, useRef, useLayoutEffect } from "react";
  import "ag-grid-community/dist/styles/ag-grid.css";
  import "ag-grid-community/dist/styles/ag-theme-alpine.css";
  import { DrawerProps } from "antd/es/drawer";
  import Entitled from "../assets/ent-logo-min.png";
  import { TopHeader } from "./common/topHeader";
  import { getBenefits, getUsers, updateBulk, updateBulkUser } from "../utilities/request";
  import { BtnCellRenderer } from "./button";
  import { Document } from "./icons/documents";
  import { Message } from "./icons/message";
  import { Employee } from "./icons/employee";
  import { Activerequest } from "./icons/activerequests";
  import { Enach } from "./icons/enach";
  import { LogoutOutlined, AlignCenterOutlined, DoubleLeftOutlined } from "@ant-design/icons";
  import axios from "axios";
  import { Loan,Benifit } from "../types";
  import { filterOptionStatus } from "../utilities/filteroptions";


export const Operation = () => {
    const gridRef: any = useRef(null);
    const [gridApi, setGridApi] = useState(null);
    const groupDisplayType = "singleColumn";
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const gridApiRef = useRef<any>(null);
    const [view, setView] = useState(false);
    let r: any = 0;
    const [selectedRows, setSelectedRows] = useState(0);
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [states, setStates] = useState<any>(null);
    const [rowData, setRowData] = useState<Array<Benifit>>([]);
    const [visible, setVisible] = useState(false);
    const [currentState, setCurrentState] = useState("");
    const [mobile_number, setMobileNumber] = useState("");
    const [option, setOption] = useState("");
    const [optionuser, setOptionUser] = useState("");
    const [isDrawer, setIsDrawer] = useState(false);
    const { Option } = Select;
    const [currentpage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [size, setSize] = useState(20);
    const [users, setUsers] = useState([]);
    const [role, setRole] = useState("");
    const { RangePicker } = DatePicker;
    const [start_date, setStartDate] = useState(
      moment().subtract(1, "months").format("YYYY-MM-DD")
    );
    const [end_date, setEndDate] = useState(moment().add(1,'days').format("YYYY-MM-DD"));
    const showDrawer = () => {
      setIsDrawer(true);
    };
    const onClose = () => {
      setIsDrawer(false);
    };
    let [filterOptionsStatus, setOptionStatus] = useState<any>(null);
    function updateDimension() {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }
    function parseStatus(params: any) {
      console.log(params)
      if (params.data.request_status) {
        return String(params.data.request_status).replaceAll("_"," ");
      } else {
        return "";
      }
    }
    function parseSalarydate2(params: any) {
      if (params.data.salary_2) {
        let date = params.data.salary_2.includes("T")
          ? params.data.salary_2.split("T")[0].split("-")
          : params.data.salary_2.split(" ")[0].split("-");
        return `${date[2]}/${date[1]}/${date[0]}`;
      } else {
        return "";
      }
    }
    function parseSalarydate3(params: any) {
      if (params.data.salary_3) {
        let date = params.data.salary_3.includes("T")
          ? params.data.salary_3.split("T")[0].split("-")
          : params.data.salary_3.split(" ")[0].split("-");
        return `${date[2]}/${date[1]}/${date[0]}`;
      } else {
        return "";
      }
    }
  
    function AssignedTo(params: any) {
      if (params.data.user_name) {
        return params.data.user_name;
      } else {
        return "Not Assigned";
      }
    }
    function parseAssignedToDate(params: any) {
      if (params.data.user_assigned_at) {
        let date = params.data.user_assigned_at.includes("T")
          ? params.data.user_assigned_at.split("T")[0].split("-")
          : params.data.user_assigned_at.split(" ")[0].split("-");
        return `${date[2]}/${date[1]}/${date[0]}`;
      } else {
        return "";
      }
    }
    function parseLatestSalaryDays(params: any) {
      let salarydate = params.data.salary_1
        ? params.data.salary_1
        : params.data.salary_2
        ? params.data.salary_2
        : params.data.salary_3;
     if(salarydate){
      let latest_salary_day = moment(salarydate);
      let today = moment(new Date(params.data.date_of_enquiry).toISOString());
      return today.diff(latest_salary_day, "days");
     }
     else{
       return 0
     }
    
    }
    function parseLoanData(data: any) {
      data.forEach((element: any) => {
        if (element["salaries"]) {
          element["salary_1"] = element["salaries"][0]
            ? element["salaries"][0]["date"]
            : "";
          element["salary_2"] = element["salaries"][1]
            ? element["salaries"][1]["date"]
            : "";
          element["salary_3"] = element["salaries"][2]
            ? element["salaries"][2]["date"]
            : "";
        } else {
          element["salary_1"] = "";
          element["salary_2"] = "";
          element["salary_3"] = "";
        }
      });
  
      return data;
    }
    function Experience(params: any){
  
      if(params.data.experience){
        return Number(params.data.experience)
      }
      else{
        return ""
      }
    }
    function RouteGuard(){
      let check: boolean = false;
      if(localStorage.getItem("token") && localStorage.getItem("user")){
         check = true
      }
     return check;
    }
    const ApplyFilter = (type: any = null) => {
      console.log(mobile_number)
      var hardcodedFilter = {
          whatsapp_no: {
              type: "equals",
              filterType: "text",
              filter: mobile_number,
          },
      }; 
  
  
      if (gridApiRef.current) {
              const filter = gridApiRef.current.getFilterModel();
              console.log(filter)
  
              console.log("deleye filter")
              gridApiRef.current.setFilterModel({
                  // ...filter,
                  ...hardcodedFilter
              });
  
              const filter2 = gridApiRef.current.getFilterModel();
              console.log(filter2)
        }
      // if (mobile_number.length !== 0) {
  
      //     if (mobile_number.length == 0) {
      //         {
      //             //@ts-ignore
      //             delete hardcodedFilter["whatsapp_no"];
      //         }
      //     }
  
      //     if (gridApiRef.current) {
      //         const filter = gridApiRef.current.getFilterModel();
      //         delete filter["whatsapp_no"];
      //         console.log("deleye filter")
      //         gridApiRef.current.setFilterModel({
      //             ...filter,
      //             ...hardcodedFilter
      //         });
      //     }
      // } else {
      //     if (gridApiRef.current) {
      //         const filter = gridApiRef.current.getFilterModel();
      //         delete filter["whatsapp_no"];
  
      //         gridApiRef.current.setFilterModel({
      //           ...filter,
      //           ...hardcodedFilter
      //       });
      //         //  gridRef.current.api.deselectAll();
      //     }
      // }
  
  };
  
    useEffect(() => {
      console.log("useeffect enter")
      // if(gridApiRef.current){
      //   gridRef.current.api.deselectAll();
      // }
  
      ApplyFilter();
    }, [mobile_number]);
    useEffect(() => {
      (async () => {
      
        const routeguard: boolean = RouteGuard();
        if(routeguard){
          let user: any = {};
          // @ts-ignore
          user = JSON.parse(localStorage.getItem("user"));
          console.log("user is ", user);
          setRole(user["role"]);
          const loans = await getBenefits([...OperationStatus], start_date, end_date, user['id'], user["role"]);
          // set filter options status 
          setOptionStatus(filterOptionStatus([...OperationStatus]))
          const users = await getUsers();
          setUsers(users);
          console.log(loans)
          //console.log(parseLoanData(loans["result"]));
          setRowData(loans.data);
          //setTotal(loans["count"]);
          setStates(OperationStatus);
          setVisible(true);
        }
        else{
          window.location.href = "/no_cred"
        }
       
       
      })();
    }, [currentpage, size, start_date, end_date]);
    useLayoutEffect(() => {
      window.addEventListener("resize", updateDimension);
      updateDimension();
      return () => window.removeEventListener("resize", updateDimension);
    }, []);
    const onGridReady = (params: any) => {
      gridApiRef.current = params.api;
      setGridApi(params.api);
      setGridColumnApi(params.columnApi);
    };
    const onButtonClick = async () => {
      const selectedNodes = gridRef.current.api.getSelectedNodes();
      const selectedData = selectedNodes.map((node: any) => node.data);
      let request_ids: any = [];
      selectedData.forEach((element: any) => {
        console.log(element)
        request_ids.push(Number(element["id"]));
      });
      let data = {
        request_ids: request_ids,
        status: option,
      };
      if (request_ids.length == 0) {
        message.warning("Please select a row  to proceed");
      } else if (option.length == 0) {
        message.warning("Please select an option  to proceed");
      } else {
        setVisible(true);
        const loans = await updateBulk(data);
        window.location.reload();
      }
    };
    const assignUser = async () => {
      const selectedNodes = gridRef.current.api.getSelectedNodes();
      const selectedData = selectedNodes.map((node: any) => node.data);
      let request_ids: any = [];
      selectedData.forEach((element: any) => {
        request_ids.push(Number(element["id"]));
      });
      let data = {
        request_ids: request_ids,
        user_id: Number(optionuser),
      };
      if (request_ids.length == 0) {
        message.warning("Please select a row  to proceed");
      } else if (optionuser.length == 0) {
        message.warning("Please select an option  to proceed");
      } else {
        setVisible(true);
        const loans = await updateBulkUser(data);
        window.location.reload();
      }
    };
  
    const filterOptions = [
      "empty",
      {
        displayKey: "blanks",
        displayName: "Blanks",
        test: function (filterValue: any, cellValue: any) {
          return cellValue == "";
        },
        hideFilterInput: true,
      },
      {
        displayKey: "notblanks",
        displayName: "Not Blanks",
        test: function (filterValue: any, cellValue: any) {
          return cellValue !== "";
        },
        hideFilterInput: true,
      },
      "equals",
      "notEqual",
      "lessThan",
      "lessThanOrEqual",
      "greaterThan",
      "greaterThanOrEqual",
      "inRange",
    ];
  
    const filterOptionsText: any= [
      "empty",
      {
        displayKey: "blanks",
        displayName: "Blanks",
        test: function (filterValue: any, cellValue: any) {
          return cellValue == "";
        },
        hideFilterInput: true,
      },
      {
        displayKey: "notblanks",
        displayName: "Not Blanks",
        test: function (filterValue: any, cellValue: any) {
          return cellValue !== "";
        },
        hideFilterInput: true,
      },
      "equals",
      "notEqual",
      "contains",
      "lessThan",
      "lessThanOrEqual",
      "greaterThan",
      "greaterThanOrEqual",
      "inRange",
    ];
    const dateFilterParams = {
      filterOptions: filterOptions,
  
      // provide comparator function
      comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
        const dateAsString = cellValue;
  
        if (!dateAsString) {
          return
        }
  
        // In the example application, dates are stored as dd/mm/yyyy
        // We create a Date object for comparison against the filter date
        const dateParts = dateAsString.split("/");
        const day = Number(dateParts[0]);
        const month = Number(dateParts[1]) - 1;
        const year = Number(dateParts[2]);
        const cellDate = new Date(year, month, day);
  
        // Now that both parameters are Date objects, we can compare
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        } else if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
        return 0;
     
      },
    };
  
  
    const filterOptionsPresent: any = [
      "empty",
      {
        displayKey: "present",
        displayName: "Present",
        test: function (filterValue: any, cellValue: any) {
          return cellValue == "true";
        },
        hideFilterInput: true,
      },
      {
        displayKey: "notpresent",
        displayName: "Not Present",
        test: function (filterValue: any, cellValue: any) {
          return cellValue == "false";
        },
        hideFilterInput: true,
      },
    ];
  
     filterOptionsStatus =[
      "empty",
      {
        displayKey: "ORIGINATED",
        displayName: "ORIGINATED",
        test: function (filterValue: any, cellValue: any) {
          return cellValue == "ORIGINATED";
        },
        hideFilterInput: true,
      },
      {
        displayKey: "notpresent",
        displayName: "Not Present",
        test: function (filterValue: any, cellValue: any) {
          return cellValue == "false";
        },
        hideFilterInput: true,
      },
    ];
  
    function resetAllFilters(){
      setMobileNumber("");
      gridApiRef.current.setFilterModel(null);
      
    }
  
   
    function parseDate(params: any) {
      if (params.data.date_of_enquiry) {
        let date = params.data.date_of_enquiry.split("T")[0].split("-");
        return `${date[2]}/${date[1]}/${date[0]}`;
      } else {
        return "";
      }
    }
  
    function parseCreatedOn(params: any) {
      if (params.data.created_at) {
          return `${moment(params.data.created_at).format("YYYY-MM-DD")}`
      } else {
          return "Not Initated";
      }
  }
    const columnDefs = [
      // {
      //   field: "loan_id",
      //   minWidth: 160,
      //   headerName: "Request Id",
      //   filterParams: {
      //     filterOptions: filterOptionsText,
      //   },
      //   filter: true,
      //   cellStyle: { fontSize: "12px" },
      // },
      // {
      //   field: "amount_granted",
      //   minWidth: 120,
      //   headerName: "Amount granted",
      //   filter:true,
      //   pinned: "left",
      //   filterParams: {
      //     filterOptions: filterOptionsText,
      //   },
      //   cellStyle: { fontSize: "12px" },
      //   headerCheckboxSelection: true,
      //   checkboxSelection: true,
      // },
  
      {
        field: "channel",
        minWidth: 120,
        headerName: "Channel",
        filter:true,
        filterParams: {
          filterOptions: filterOptionsText,
        },
        cellStyle: { fontSize: "12px" },
        pinned: "left",
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      {
        field: "scheme",
        minWidth: 120,
        headerName: "Scheme",
        filter: true,
        filterParams: {
          filterOptions: filterOptionsText,
        },
        cellStyle: { fontSize: "12px" },
        pinned: "left",
      }, 
      // {
      //   headerName: "Loan Period (Months)",
      //   field: "loan_period",
      //   filter: true,
      //   filterParams: {
      //     filterOptions: filterOptionsStatus,
      //   },
      //   cellStyle: { fontSize: "12px" },
      //   minWidth: 180,
      // },
      // {
      //   headerName: "Salary",
      //   field: "salary",
      //   filter: true,
      //   filterParams: {
      //     filterOptions: filterOptionsText,
      //   },
      //   cellStyle: { fontSize: "12px" },
      //   minWidth: 160,
      // },
      {
        headerName: "Full Name",
        field: "full_name",
        sortable: true,
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: filterOptionsText,
        },
        cellStyle: { fontSize: "12px" },
        minWidth: 140,
      },
      {
        headerName: "Mobile Number",
        field: "whatsapp_no",
        filter: true,
        filterParams: {
          filterOptions: filterOptionsText,
        },
        sortable: true,
        cellStyle: { fontSize: "12px" },
        minWidth: 140,
      },
      {
        headerName: "Request Status",
        field: "request_status",
        filterParams: dateFilterParams,
        filter: "agDateColumnFilter",
        valueGetter: parseStatus,
        sortable: true,
        cellStyle: { fontSize: "12px" },
        minWidth: 140,
      },
      {
        field: "created_at",
        minWidth: 140,
        headerName: "Created On",
        filter: true,
        filterParams: {
          filterOptions: filterOptionsPresent,
        },
        valueGetter: parseCreatedOn,
        sortable: true,
        cellStyle: { fontSize: "12px" },
      },
      // {
      //   headerName: "Second Salary Date",
      //   field: "salary_2",
      //   filterParams: dateFilterParams,
      //   filter: "agDateColumnFilter",
      //   valueGetter: parseSalarydate2,
      //   sortable: true,
      //   cellStyle: { fontSize: "12px" },
      //   minWidth: 160,
      // },
      // {
      //   headerName: "Third Salary Date",
      //   field: "salary_3",
      //   filterParams: dateFilterParams,
      //   filter: "agDateColumnFilter",
      //   valueGetter: parseSalarydate3,
      //   sortable: true,
      //   cellStyle: { fontSize: "12px" },
      //   minWidth: 150,
      // },
      // {
      //   headerName: "Days From Latest Salary",
      //   field: "latest_salary",
      //   sortable: true,
      //   filter: "agNumberColumnFilter",
      //   filterParams: {
      //     filterOptions: filterOptionsText,
      //   },
      //   valueGetter: parseLatestSalaryDays,
      //   cellStyle: { fontSize: "12px" },
      //   minWidth: 200,
      // },
  
      // {
      //   field: "full_name",
      //   headerName: "Full Name",
      //   filter: true,
      //   filterParams: {
      //     filterOptions: filterOptionsText,
      //   },
      //   cellStyle: { fontSize: "12px" },
      //   minWidth: 140,
      // },
      // {
      //   field: "user_assigned_at",
      //   headerName: "Assigned on ",
      //   valueGetter: parseAssignedToDate,
      //   pinned: "right",
      //   sortable: true,
      //   filterParams: dateFilterParams,
      //   filter: "agDateColumnFilter",
      //   cellStyle: { fontSize: "12px" },
      //   minWidth: 140,
      // },
      {
        field: "user_name",
        headerName: "Assigned to ",
        valueGetter: AssignedTo,
        pinned: "right",
        filterParams: {
          filterOptions: filterOptionsText,
        },
        filter: true,
        cellStyle: { fontSize: "12px" },
        minWidth: 140,
      },
      {
        field: "id",
        headerName: "Description",
        pinned: "right",
        minWidth: 180,
        suppressSizeToFit: true,
        cellRenderer: "btnCellRenderer",
        cellStyle: { fontSize: "12px" },
      },
    
      // {
      //   field: "mobile_number",
      //   headerName: "Mobile",
      //   filter: true,
      //   filterParams: {
      //     filterOptions: filterOptionsText,
      //   },
      //   cellStyle: { fontSize: "12px" },
      //   minWidth: 140,
      // },
      // {
      //   field: "experience",
      //   headerName: "Experience (Months)",
      //   filter: "agNumberColumnFilter",
      //   valueGetter: Experience,
      //   filterParams: {
      //     filterOptions: filterOptionsText,
      //   },
      //   sortable: true,
      //   cellStyle: { fontSize: "12px" },
      //   minWidth: 160,
      // },
      // {
      //   field: "employee_present",
      //   headerName: "Employee Present",
      //   filter: true,
      //   filterParams: {
      //     filterOptions: filterOptionsPresent,
      //   },
      //   cellRenderer: "employee",
      //   cellStyle: { fontSize: "12px" },
      //   minWidth: 140,
      // },
      // {
      //   field: "enach_possible",
      //   headerName: "Enach Possible",
      //   filter: true,
      //   filterParams: {
      //     filterOptions: filterOptionsPresent,
      //   },
      //   cellRenderer: "enach",
      //   cellStyle: { fontSize: "12px" },
      //   minWidth: 140,
      // },
      // {
      //   field: "active_requests",
      //   headerName: "Active Requests",
      //   cellRenderer: "active_request",
      //   filter: true,
      //   filterParams: {
      //     filterOptions: filterOptionsPresent,
      //   },
      //   cellStyle: { fontSize: "12px" },
      //   minWidth: 140,
      // },
      // {
      //   field: "message_sent",
      //   minWidth: 140,
      //   headerName: "Notified",
      //   filter: true,
      //   filterParams: {
      //     filterOptions: filterOptionsPresent,
      //   },
      //   cellRenderer: "message",
      //   cellStyle: { fontSize: "12px" },
      // },
    ];
    function returnName(name: string) {
      let result = "";
      name.split("_").forEach((el) => {
        result = result + " " + el.toUpperCase();
      });
      return result;
    }
  
    const onRowSelected = (event: any) => {
      let selectedRowsTemp = gridApiRef.current.getSelectedRows().length;
      setSelectedRows(selectedRowsTemp);
      console.log("selected rows are ", selectedRows);
    };
  
    const onSelectionChanged = (event: any) => {
      // console.log("event is selection chanfegegegegeg ",event)
    };
  
    function handleChange(value: any) {
      setOption(value);
    }
    function handleChangeUser(value: any) {
      setOptionUser(value);
    }
    function onChangePage(page: number, pageSize?: number) {
      if (pageSize) {
        setSize(pageSize);
      }
      setCurrentPage(page);
    }
    const onDateChange = (dates: any, dateStrings: any) => {
      setStartDate(dates[0].format("YYYY-MM-DD"));
      setEndDate(dates[1].format("YYYY-MM-DD"));
    };
    return (
      <>
        {visible == true ? (
          <div style={{ backgroundColor: "#f0f0f0" }}>
          <TopHeader/>
            <Row
              style={{
                background: "",
                marginLeft: "5%",
                marginRight: "5%",
                marginTop: "1%",
                boxShadow: "10px 10px grey;",
              }}
            >
              <Col span={12}>
            
     
              <div>
             <DoubleLeftOutlined style={{ fontSize:"20px" ,marginTop:"2%"}} onClick={resetAllFilters} /> <span onClick={resetAllFilters} style={{cursor:"pointer"}} >Reset All Filters</span></div>
  
            
                {/* <p style={{fontWeight:380, fontSize:"15px"}}>Toolbar</p> */}
              </Col>
              <Col span={4} style={{ padding: "1%" }}>
                  <Input
                    placeholder="Mobile No"
                    value={mobile_number}
                    onChange={(e) => {
                      setMobileNumber(e.target.value);
                    }}
                  />
            </Col>
              <Col span={6} style={{ padding: "1%" }}>
                <RangePicker
                  format="DD-MM-YYYY"
                  onChange={onDateChange}
                  defaultValue={[moment().subtract(1, "months"), moment()]}
                />
              </Col>
              <Col span={2} style={{ background: "#f0f0f0", padding: "1%" }}>
                <Tooltip title="Click here for actions">
                  <AlignCenterOutlined
                    style={{ fontSize: "20px", cursor: "pointer" }}
                    onClick={showDrawer}
                  />
                </Tooltip>
              </Col>
            </Row>
            <Drawer
              title="Actions"
              placement="right"
              onClose={onClose}
              visible={isDrawer}
              width={450}
            >
              <Card style={{ padding: "", marginTop: "4%" }}>
                <Col>
                  <h6 style={{ marginBottom: "5%" }}>Update Status</h6>
                </Col>
                <Col span={24}>
                  <Select
                    defaultValue="Please select an option"
                    style={{ width: "100%" }}
                    onChange={handleChange}
                  >
                    {states.map((element: any) => {
                      return (
                        <Option value={element}>
                          {element}
                        </Option>
                      );
                    })}
                  </Select>
                </Col>
                <Col style={{ marginTop: "5%" }}>
                  <Button
                    type="primary"
                    block
                    onClick={() => {
                      onButtonClick();
                    }}
                  >
                    Bulk Update
                  </Button>
                </Col>
              </Card>
              {role == "admin" ? (
                <>
                  <Card style={{ padding: "", marginTop: "4%" }}>
                    <Col>
                      <h6 style={{ marginBottom: "5%" }}>Assign User</h6>
                    </Col>
                    <Col span={24}>
                      <Select
                        defaultValue="Please select an option"
                        style={{ width: "100%" }}
                        onChange={handleChangeUser}
                      >
                        {users.map((element: any) => {
                          return (
                            <Option value={element["id"]}>
                              {element["user_name"]}
                            </Option>
                          );
                        })}
                      </Select>
                    </Col>
                    <Col style={{ marginTop: "5%" }}>
                      <Button
                        type="primary"
                        block
                        onClick={() => {
                          assignUser();
                        }}
                      >
                        Assign
                      </Button>
                    </Col>
                  </Card>
                </>
              ) : (
                ""
              )}
            </Drawer>
            <Row>
            <Col span={24}>
              <div
                className="ag-theme-alpine"
                style={{
                  height: height * 0.8,
                  width: width * 0.87,
                  paddingLeft: "1%",
                  paddingRight: "",
                  paddingTop: "1%",
                  marginTop: "",
                  fontSize: "12px",
                }}
              >
                <AgGridReact
                  ref={gridRef}
                  rowData={rowData}
                  rowSelection="multiple"
                  columnDefs={columnDefs}
                  enableCellTextSelection={true}
                  pagination={true}
                  paginationPageSize={5000}
                  groupDisplayType="singleColumn"
                  onGridReady={onGridReady}
                  onRowSelected={onRowSelected}
                  onSelectionChanged={onSelectionChanged}
                  defaultColDef={{
                    flex: 1,
                    minWidth: 110,
                    resizable: true,
                  }}
                  frameworkComponents={{
                    btnCellRenderer: BtnCellRenderer,
                    document: Document,
                    employee: Employee,
                    active_request: Activerequest,
                    enach: Enach,
                    message:Message
                  }}
                ></AgGridReact>
              </div>
              {/* <Pagination
                style={{ marginLeft: "40%", marginTop:"1%" }}
                current={currentpage}
                onChange={onChangePage}
                total={total}
                defaultPageSize={20}
              /> */}
            </Col>
          </Row>
          </div>
        ) : (
          <Spin size="large" style={{ marginLeft: "40%", marginTop: "20%" }} />
        )}
      </>
    );
}


  