
import {
    Row,
    Col,
    Divider,
    Card,
    Pagination,
    Statistic,
    Spin,
    Descriptions,
    Badge,
    Tooltip,
    Button,
    Select,
    Typography,
    Drawer,
    message,
    DatePicker,
    Table,
} from "antd";
import {
    HighlightOutlined,
    FileOutlined,
    CheckCircleFilled,
    CloseCircleFilled,
    UploadOutlined,
    DownloadOutlined,
    PlusCircleTwoTone,
} from "@ant-design/icons";
import { CSVLink, CSVDownload } from "react-csv";
import { useEffect, useState, useRef, useLayoutEffect } from "react";
import moment from "moment";
import { getBenefits, getLoanswithStatus } from "../../utilities/request";
import { clientStatus, OperationStatus } from "../../utilities/status";
import { TopHeader } from "../common/topHeader";
import { LogoutOutlined, AlignCenterOutlined, DoubleLeftOutlined } from "@ant-design/icons";
import usePrevious from "../../utilities/previous";
import { AgGridReact } from "ag-grid-react";
const { RangePicker } = DatePicker;


export function LeadRequest() {
    const gridRef: any = useRef(null);
    const [gridApi, setGridApi] = useState(null);
    const [selectedRows, setSelectedRows] = useState(0);
    const groupDisplayType = "singleColumn";
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    //const gridApiRef = useRef<any>(null);
    const [view, setView] = useState(false);
    const [defaultstatus, setDefaultStatus] = useState("Select Status");
    const [data, setData] = useState<any>("");
    const [exportData, setExportData] = useState<any>("");
    const [rowData, setRowData] = useState<Array<any>>([]);
    const [option, setOption] = useState("");
    const prev_option = usePrevious(option);
    const [currentpage, setCurrentPage] = useState(1);
    const [size, setSize] = useState(5000);
    const [total, setTotal] = useState(0);
    const { Option } = Select;
    const gridApiRef = useRef<any>(null);
    const states: any = [
        "originated",
        "flow_complete",
        "assigned_to_ops",
        "data_complete",
        "data_verified",
        "payment_inititated",
        "payment_done",
        "shared_with_partner",
        "enrolled",
        "support",
        "rejected",
        "follow_up_1",
        "follow_up_2",
        "not_responded"
    ]
    const [start_date, setStartDate] = useState(
        moment().subtract(1, "months").format("YYYY-MM-DD")
    );
    const [end_date, setEndDate] = useState(moment().add(1, 'days').format("YYYY-MM-DD"));

    function handleChange(value: any) {
        setOption(value);
        setDefaultStatus(value)
    }
    function parseStatus(params: any) {
        //console.log(params)
        if (params.data.request_status) {
            return String(params.data.request_status).replaceAll("_", " ").charAt(0).toUpperCase() + String(params.data.request_status).replaceAll("_", " ").slice(1);;
        } else {
            return "";
        }
    }

    function parseChatInitated(params: any) {
        if (params.data.chat_initiated_on) {
            return `${moment(params.data.chat_initiated_on).format("YYYY-MM-DD")}`
        } else {
            return "Not Initated";
        }
    }

    function parselastUpdated(params: any) {
        if (params.data.last_status_updated_at) {
            return `${moment(params.data.last_status_updated_at).format("YYYY-MM-DD")}`
        } else {
            return "Not Updated";
        }
    }

    const onGridReady = (params: any) => {
        gridApiRef.current = params.api;
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        console.log(params)
    };

    const filterOptions = [
        "empty",
        {
            displayKey: "blanks",
            displayName: "Blanks",
            test: function (filterValue: any, cellValue: any) {
                return cellValue == "";
            },
            hideFilterInput: true,
        },
        {
            displayKey: "notblanks",
            displayName: "Not Blanks",
            test: function (filterValue: any, cellValue: any) {
                return cellValue !== "";
            },
            hideFilterInput: true,
        },
        "equals",
        "notEqual",
        "lessThan",
        "lessThanOrEqual",
        "greaterThan",
        "greaterThanOrEqual",
        "inRange",
    ];
    const dateFilterParams = {
        filterOptions: filterOptions,

        // provide comparator function
        comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
            const dateAsString = cellValue;
            if (!dateAsString) {
                return
            }

            if (moment(dateAsString).unix() < moment(filterLocalDateAtMidnight).unix()) {
                return -1;
            } else if (moment(dateAsString).unix() > moment(filterLocalDateAtMidnight).unix()) {
                return 1;
            }
            return 0;

        },
    };

    const columnDefs = [
        {
            field: "channel",
            headerName: "channel",
            filter: true,
            cellStyle: { fontSize: "12px" },
            minWidth: 140,
        },
        {
            field: "scheme",
            headerName: "Scheme",
            filter: true,
            cellStyle: { fontSize: "12px" },
            minWidth: 140,
        },
        {
            field: "whatsapp_no",
            headerName: "Mobile",
            filter: true,
            cellStyle: { fontSize: "12px" },
            minWidth: 140,
        },
        {
            field: "full_name",
            headerName: "Full Name",
            filter: true,
            cellStyle: { fontSize: "12px" },
            minWidth: 140,
        },
        {
            field: "request_status",
            headerName: "Status",
            valueGetter: parseStatus,
            filter: true,
            cellStyle: { fontSize: "12px" },
            minWidth: 140,
        },
        {
            field: "chat_initiated_on",
            headerName: "Initiated On",
            valueGetter: parseChatInitated,
            filterParams: dateFilterParams,
            filter: "agDateColumnFilter",
            sortable: true,
            cellStyle: { fontSize: "12px" },
            minWidth: 140,
        },
        {
            field: "last_status_updated_at",
            headerName: "Last Updated",
            valueGetter: parselastUpdated,
            filterParams: dateFilterParams,
            filter: "agDateColumnFilter",
            sortable: true,
            cellStyle: { fontSize: "12px" },
            minWidth: 140,
        },
    ];
    async function resetAllFilters() {
        gridApiRef.current.setFilterModel(null);
        setDefaultStatus("Select Status")
        setStartDate(moment().subtract(1, "months").format("YYYY-MM-DD"))
        setEndDate(moment().add(1, 'days').format("YYYY-MM-DD"))
        const loans = await getBenefits([...clientStatus], start_date, end_date, "0", "admin", currentpage, size);
        setRowData(loans.data)

    }
    const onDateChange = (dates: any, dateStrings: any) => {
        setStartDate(dates[0].format("YYYY-MM-DD"));
        setEndDate(dates[1].format("YYYY-MM-DD"));
    };

    const onRowSelected = (event: any) => {
        let selectedRowsTemp = gridApiRef.current.getSelectedRows().length;
        setSelectedRows(selectedRowsTemp);
        console.log("selected rows are ", selectedRows);
    };

    const onSelectionChanged = async (event: any) => {
        //@ts-ignore
        const nodes = await gridApi.getRenderedNodes()
        console.log(nodes)
        // console.log("event is selection chanfegegegegeg ",event)
    };
    function onChangePage(page: number, pageSize?: number) {
        console.log(page)
        console.log(pageSize)

        if (pageSize) {
            setSize(pageSize);
        }
        setCurrentPage(page);
    }

    function downloadExport() {

        let data: any = [];
        let rowData: any[] = [];
        //@ts-ignore
        gridApi.forEachNode(node => rowData.push(node.data));
        rowData.forEach((row)=>{
            data.push({
                "channel": row.channel || " ",
                "full_name": row.full_name,
                "mobile": row.whatsapp_no,
                "scheme": row.scheme,
                "status": row.request_status,
                "chat_initated": row.chat_initiated_on != null ? moment(row.chat_initiated_on).format("DD-MM-YYYY") : "not Initiated",
                "last_updated": row.last_status_updated_at != null ? moment(row.last_status_updated_at).format("DD-MM-YYYY") : "not updated"
            })
        })
        setExportData(data)
    
    }
    useEffect(() => {
        (async () => {
            let loans: any;
            if (prev_option == option) {
                loans = await getBenefits([...clientStatus], start_date, end_date, "0", "admin", currentpage, size);
            }
            else {
                loans = await getBenefits([option], start_date, end_date, "0", "admin", currentpage, size);
            }
            //@ts-ignore

            //console.log(loans)
            setRowData(loans.data)
            setData(loans.data)
            setTotal(loans.count)
        })();

    }, [start_date, end_date, currentpage, option])

    function updateDimension() {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }

    useLayoutEffect(() => {
        window.addEventListener("resize", updateDimension);
        updateDimension();
        return () => window.removeEventListener("resize", updateDimension);
    }, []);


    return (
        <div>
            <TopHeader />
            <Row
                style={{
                    background: "",
                    marginLeft: "5%",
                    marginRight: "5%",
                    marginTop: "1%",
                    boxShadow: "10px 10px grey;",
                }}
            >
                <Col span={10} style={{padding:"1%" }} >
                    <div>
                        <DoubleLeftOutlined style={{ fontSize: "20px", marginTop: "2%" }} onClick={resetAllFilters} /> <span onClick={resetAllFilters} style={{ cursor: "pointer" }} >Reset All Filters</span>
                        <span>

                            <CSVLink
                                style={{ fontSize: "14px", marginLeft: "3%" }}
                                data={exportData}
                                filename={"leads.csv"}
                                className="btn btn-primary"
                                onClick={() => { downloadExport() }}
                            >
                                Download Export
                            </CSVLink></span>
                    </div>
                </Col>
                <Col span={4} style={{ padding: "1%" }}>

                </Col>
                <Col span={5} style={{ padding: "1%" }}>
                    <RangePicker
                        format="DD-MM-YYYY"
                        onChange={onDateChange}
                        defaultValue={[moment().subtract(1, "months"), moment()]}
                    />
                </Col>
                <Col span={4} style={{ padding: "1%" }}>
                    <Select
                        value={defaultstatus}
                        style={{ width: "100%" }}
                        onChange={handleChange}
                    >
                        {states.map((element: any) => {
                            return (
                                <Option value={element}>
                                    {element}
                                </Option>
                            );
                        })}
                    </Select>
                </Col>
            </Row>
            {/* <div style={{
                marginLeft: "5%",
                marginRight: "5%",
            }}> */}
            <Row>
                <Col span={24}>
                    <div
                        className="ag-theme-alpine"
                        style={{
                            height: height * 0.8,
                            width: width * 0.87,
                            paddingLeft: "1%",
                            paddingRight: "",
                            paddingTop: "1%",
                            marginTop: "",
                            fontSize: "12px",
                        }}
                    >
                        <AgGridReact
                            ref={gridRef}
                            rowData={rowData}
                            rowSelection="multiple"
                            columnDefs={columnDefs}
                            enableCellTextSelection={true}
                            pagination={true}
                            paginationPageSize={5000}
                            groupDisplayType="singleColumn"
                            onGridReady={onGridReady}
                            onRowSelected={onRowSelected}
                            onSelectionChanged={onSelectionChanged}
                            defaultColDef={{
                                flex: 1,
                                minWidth: 110,
                                resizable: true,
                            }}
                            frameworkComponents={{
                                // btnCellRenderer: BtnCellRenderer,
                                // document: Document,
                                // employee: Employee,
                                // active_request: Activerequest,
                                // enach: Enach,
                                // message: Message
                            }}
                        ></AgGridReact>
                    </div>
                    {/* <Pagination
                style={{ marginLeft: "40%", marginTop:"1%" }}
                current={currentpage}
                onChange={onChangePage}
                total={total}
                defaultPageSize={20}
              /> */}
                </Col>
            </Row>
        </div>
        // </div>
    )
}




