import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import { Sidebar } from "./components/sidebar";
import { DatePicker, Layout, message } from "antd";
import "antd/dist/antd.css";
import "./index.css";
import { Provider } from "react-redux";
import { store } from "./state";
function App() {
  const [path, setPath] = useState("");
  useEffect(() => {
    setPath(window.location.pathname);
    console.log("path is ", path);
  });
  return (
    <Provider store={store}>
      <Layout>
        <Sidebar></Sidebar>
      </Layout>
    </Provider>
  );
}

export default App;