import { Button, Tooltip } from "antd";
import { Redirect } from "react-router-dom";
import { useEffect } from "react";
export function BtnCellRenderer(props: any) {
  console.log(props)
  function onPress(id: any): any {
    window.location.href = `/benifit?id=${props["data"]["id"]}`;
  }
  return (
    <Tooltip
      title={"Click here to go to details"}
    >
      <Button
        type="primary"
        onClick={onPress}
      >
        Show Details
      </Button>
    </Tooltip>
  );
}
